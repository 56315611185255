<script setup lang="ts">
import type { SbFlexStoryblok } from '~/types/storyblok'

const props = defineProps<{ blok: SbFlexStoryblok }>()

const responsive = useResponsive()

const reRenderKey = ref(0)

const gapStyle = computed(() => {
  const style = {
    rowGap: '',
    columnGap: '',
  }

  if (responsive.value.mobile && props.blok.rowGapPhone) {
    style.rowGap = `${Number(props.blok.rowGapPhone) * 4}px`
  }
  else if (props.blok.rowGap) {
    style.rowGap = `${Number(props.blok.rowGap) * 4}px`
  }

  if (responsive.value.mobile && props.blok.columnGapPhone) {
    style.columnGap = `${Number(props.blok.columnGapPhone) * 4}px`
  }
  else if (props.blok.columnGap) {
    style.columnGap = `${Number(props.blok.columnGap) * 4}px`
  }

  return style
})

// To update styles on client because server is always desktop
onMounted(() => {
  if (!responsive.value.mobile) {
    reRenderKey.value++
  }
})
</script>

<template>
  <div
    :key="reRenderKey"
    v-editable="blok"
    class="flex"
    :class="{
      'flex-wrap': blok.wrap,
      'w-full': blok.fullWidth,
      'h-full': blok.fullHeight,
    }"
    :style="{
      ...gapStyle,
      alignItems: blok.alignItems && blok.alignItems as any,
      justifyContent: blok.justifyContent && blok.justifyContent as any,
    }"
  >
    <StoryblokComponent
      v-for="(columnBlok) in blok.content"
      :key="columnBlok._uid"
      :blok="columnBlok"
    />
  </div>
</template>
